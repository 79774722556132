// The hero image can be added to the src folder with the HeroImage.png name.
// Logo image can be added to the src folder as Logo.png
// Favicon image can be added to the public folder as favicon.ico

// Project specific values that can be changed easily.
export const projectConfiguration = {
  // Product Key
  productKey: "aiopinions",

  // Content variables
  heroLineOne: "Let AI Pick",
  heroLineTwo: "Your Best Photos",
  heroLineThree: "",
  subtitle:
    "We trained our AI on thousands of human opinions and built a machine that can predict how many likes each of your photos will get.",

  // Colors
  backgroundColor: "#0F1118",
  primaryTextColor: "#ffffff",
  secondaryTextColor: "#9FA4BC",
  tertiaryTextColor: "#D0D4EA",
  inputBackgroundColor: "#595D6F",
  inputFocusBackgroundColor: "#ffffff",
  inputTextColor: "#595D6F",
  inputFocusTextColor: "#0D0F14",
  punchColor: "#ffda2d", // Used as link color
  footerLinkColor: "#ffffff",
  buttonTextColor: "#0f1117",

  //   Fonts
  primaryFont: "Poppins",
  secondaryFont: "Inter",
};
